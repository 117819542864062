function displayAnswer(answer) {

    // document.getElementById(answer + 'Question').style.display = "block";
  
    if (answer == "eletric") { // hide the div that is not selected
  
      document.getElementById('servico-select').style.display = "";
      document.getElementById('servico2-select').style.display = "none";
      document.getElementById('servico3-select').style.display = "none";
      document.getElementById('servico4-select').style.display = "none";
  
    } else if(answer == "1"){
        document.getElementById('servico-select').style.display = "none";
        document.getElementById('servico2-select').style.display = "none";
        document.getElementById('servico3-select').style.display = "none";
        document.getElementById('servico4-select').style.display = "none";
    }
    
    if (answer == "fisic") { // hide the div that is not selected
  
        document.getElementById('cpf-select').style.display = "";
        document.getElementById('cnpj-select').style.display = "none";
    
      } else if(answer == "2"){
          document.getElementById('cpf-select').style.display = "none";
          document.getElementById('cnpj-select').style.display = "none";
      }

      if(answer == "juridic"){
        document.getElementById('cnpj-select').style.display = "";
        document.getElementById('cpf-select').style.display = "none";
      }

      if(answer == "mecanic"){
        document.getElementById('servico2-select').style.display = "";
        document.getElementById('servico-select').style.display = "none";
        document.getElementById('servico4-select').style.display = "none";
        document.getElementById('servico3-select').style.display = "none";
      }

      if(answer == "civil"){
        document.getElementById('servico3-select').style.display = "";
        document.getElementById('servico-select').style.display = "none";
        document.getElementById('servico2-select').style.display = "none";
        document.getElementById('servico4-select').style.display = "none";
      }

      if(answer == "auto"){
        document.getElementById('servico4-select').style.display = "";
        document.getElementById('servico3-select').style.display = "none";
        document.getElementById('servico-select').style.display = "none";
        document.getElementById('servico2-select').style.display = "none";
      }
    
  }